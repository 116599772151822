import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query RamadanFR {
    heroImage: file(relativePath: {eq: "project-ramadan-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Ramadan Baskets: Les meilleures des actions durant le meilleur des mois." description="Whoever feeds the person who is breaking his fast, he will have his reward (for his fasting) without decreasing anything from the reward of the fasting person." mdxType="SEO" />
    <CauseHero purpose="ramadan" title="Ramadan Baskets" text="Les meilleures des actions durant le meilleur des mois." data={props.data} pagePath={props.path} mdxType="CauseHero" />
    <div className="p-wrapper">
      <p>{`Le Messager d'Allah, que la paix et les bénédictions soient sur lui, a dit: `}<br />{`
`}<strong parentName="p">{`« Quiconque nourrit une personne qui rompt son jeûne aura sa récompense (pour son jeûne) sans rien diminuer de la récompense de la personne qui jeûne ».`}</strong></p>
      <p>{`Le Ramadan est le mois de la miséricorde et des bénédictions. Mais alors que nous passons nos journées à jeûner et avons la chance de trouver un repas pour rompre notre jeûne, il y a des frères et sœurs dans le monde qui ne peuvent pas se le permettre. En faisant un don pour notre programme `}<strong parentName="p">{`PANIERS DU RAMADAN`}</strong>{`, vous faites non seulement une bonne action pendant le meilleur des mois, mais aussi selon le hadith, obtiendrez les récompenses de votre jeûne ainsi que celles pour le jeûne des personnes que vous nourrissez. Les paniers UMMATY sont préparés selon les habitudes alimentaires de chaque pays que nous ciblons. `}<strong parentName="p">{`Chaque panier`}</strong>{` comprend des rations alimentaires diversifiées `}<strong parentName="p">{`pouvant nourrir une famille de 5 personnes durant tout un mois`}</strong>{`.`}</p>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      